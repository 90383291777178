import { WithLink } from '@/components/integrated/card/with-link';
import React from 'react';
import {
  CARD_VARIANT,
  CardProperties,
} from '@/components/integrated/card/card.types';
import NextImage from '@/components/data-display/image';
import { TextBlock } from '@/components/integrated/card/text-block';
import classNames from 'classnames';
import styles from '../card.module.scss';

const PromotionalCard = (properties: CardProperties) => {
  const {
    image,
    textBlock,
    textColour,
    backgroundColour,
    cardLink,
    isStorybook = false,
  } = properties;
  return (
    <WithLink
      href={cardLink ?? undefined}
      className={classNames(
        styles.card,
        styles[`card__${CARD_VARIANT.PROMOTIONAL_CARD}`],
      )}
    >
      {textBlock && (
        <TextBlock
          textBlock={{
            ...textBlock,
            pillLabel: undefined,
            buttonsCollection: [],
          }}
          backgroundColour={backgroundColour}
          textColour={textColour}
        />
      )}
      {image?.url && (
        <NextImage
          src={image.url}
          alt={image.description ?? ''}
          fill
          className={
            styles[
              `card__${CARD_VARIANT.PROMOTIONAL_CARD}__image-wrapper--image-right`
            ]
          }
          isStorybook={isStorybook}
        />
      )}
    </WithLink>
  );
};

export default PromotionalCard;
