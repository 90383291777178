import NextImage from '@/components/data-display/image';
import { CardProperties } from '@/components/integrated/card/card.types';
import { formatWasRate } from '@/utils/format-was-rate';
import classNames from 'classnames';
import React from 'react';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import { WithLink } from '@/components/integrated/card/with-link';
import Icon from '@/components/data-display/icon';
import { findUpToOrFrom } from '@/components/integrated/partner-tile/partner-tile';
import styles from '../card.module.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
const PartnerInlineCard = (properties: CardProperties) => {
  const {
    image,
    variant,
    backgroundColour,
    cardLink,
    isStorybook = false,
    partner,
  } = properties;
  if (partner === null || partner?.length === 0) {
    return null;
  }

  const { rate, wasRate, logoSrc, name, wasRatePrefix, destinationUrl } =
    partner[0];
  const prefixRate = findUpToOrFrom(rate ?? '');
  const rateWithoutPrefix = prefixRate && rate?.split(prefixRate)[1]?.trim();

  const prefixWasRate = findUpToOrFrom(wasRate) ?? '';
  const wasRateWithoutPrefix =
    prefixRate && wasRate?.split(prefixWasRate)[1]?.trim();

  const formattedWasRate = formatWasRate(
    wasRateWithoutPrefix ?? wasRate,
    wasRatePrefix,
  );
  return (
    <WithLink
      href={cardLink ?? destinationUrl ?? undefined}
      className={classNames(styles.card, styles[`card__${variant}`])}
      data-testid="card-id"
      backgroundColour={backgroundColour}
    >
      <div className={styles[`card__${variant}__image-container`]}>
        {image?.url && (
          <NextImage
            src={image.url}
            alt={image.description ?? ''}
            fill
            className={styles[`card__${variant}__image-wrapper`]}
            isStorybook={isStorybook}
          />
        )}
        {partner?.[0].logoSrc && (
          <div className={styles[`card__${variant}__logo`]}>
            <NextImage
              crossOrigin="anonymous"
              src={logoSrc}
              alt={name}
              width={112}
              height={48}
              isStorybook={isStorybook}
            />
          </div>
        )}
      </div>
      {partner?.[0] && (
        <div
          className={styles[`card__${variant}__description`]}
          style={{ background: backgroundColour ?? 'inherit' }}
        >
          <h3 className={styles[`card__${variant}__description__partner-name`]}>
            {name}
          </h3>
          <div
            className={styles[`card__${variant}__description__partner-wrapper`]}
          >
            <span
              className={classNames(
                styles[`card__${variant}__description__partner-rate`],
                {
                  [styles[
                    `card__${variant}__description__partner-rate--background-green`
                  ]]: wasRate,
                },
              )}
            >
              {wasRate && (
                <Icon
                  className={
                    styles[`card__${variant}__description__partner-rate__icon`]
                  }
                  variant={ICON_VARIANT.ArrowUp}
                />
              )}
              {rateWithoutPrefix ?? rate}
            </span>
            {wasRate && (
              <span
                className={
                  styles[`card__${variant}__description__partner-was-rate`]
                }
              >
                {rate && formattedWasRate}
              </span>
            )}
          </div>
        </div>
      )}
    </WithLink>
  );
};

export default PartnerInlineCard;
