import { useState, useEffect, useRef, useId } from 'react';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import trackEvent from '@/utils/track-event/track-event';
import { EVENT_NAME } from '@/utils/track-event/track-event.types';
import type { AccordionProperties } from './accordion.types';
import styles from './accordion.module.scss';

const Accordion = ({
  title,
  children,
  className = '',
  isOpen: initialIsOpen = false,
  onToggle,
  id: externalId,
  GAEventReference = null,
  as = 'p',
}: AccordionProperties) => {
  const id = useId();
  const isSelectedId = initialIsOpen === externalId;

  const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen === true);

  const [height, setHeight] = useState<number>(0);

  const elementReference = useRef<HTMLDivElement>(null);

  const handleGAEvent = () => {
    if (GAEventReference) {
      trackEvent({
        event: EVENT_NAME.NAVIGATION,
        nav_element: GAEventReference,
      });
    }
  };

  const Heading = as;

  const handleClick = () => {
    handleGAEvent();
    if (onToggle) {
      onToggle(isSelectedId ? '' : externalId);
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    setHeight(elementReference?.current?.clientHeight as number);
  }, [children]);

  return (
    <div
      className={classNames(
        styles.accordion,
        { [styles['accordion--open']]: isOpen || isSelectedId },
        className,
      )}
    >
      <Heading className={styles.accordion__heading}>
        <Button
          className={styles.accordion__title}
          variant={BUTTON_VARIANT.Unstyled}
          onClick={handleClick}
          aria-expanded={isOpen}
          aria-controls={`accordion${id}body`}
          title={title}
          id={`accordion${id}`}
        >
          {title}

          <Icon
            variant={ICON_VARIANT.ChevronDown}
            className={styles.accordion__chevron}
          />
        </Button>
      </Heading>
      <ResizeObserver
        onResize={() => {
          setHeight((elementReference?.current?.clientHeight as number) + 20);
        }}
      >
        <div
          id={`accordion${id}body`}
          role="region"
          aria-labelledby={`accordion${id}`}
          className={styles.accordion__body}
          style={
            isOpen || isSelectedId
              ? { visibility: 'visible', height: `${height}px` }
              : { visibility: 'hidden', height: 0 }
          }
        >
          <div ref={elementReference}>{children}</div>
        </div>
      </ResizeObserver>
    </div>
  );
};

export default Accordion;
