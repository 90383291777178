import React from 'react';
import classNames from 'classnames';
import Carousel from '@/components/data-display/carousel';
import { VARIANT as CAROUSEL_VARIANT } from '@/components/data-display/carousel/carousel.types';
import { ContentGridProperties } from './content-grid.types';
import styles from './content-grid.module.scss';

const ContentGrid = ({
  children,
  className = '',
  isRow = false,
  isCarousel = false,
  carouselAutoPlay = true,
  carouselVariant = CAROUSEL_VARIANT.MultiPartners,
  contentSeparators = false,
  extraPaddingBottom,
  alignContent = 'left',
}: ContentGridProperties) => {
  const numberChildren =
    React.Children.toArray(children).filter(Boolean).length;

  if (!children) {
    return null;
  }
  return isCarousel ? (
    <Carousel
      variant={carouselVariant}
      className={styles.content__carousel}
      autoPlay={carouselAutoPlay}
    >
      {React.Children.map(children, child => {
        return (
          <Carousel.Item className={styles['content__carousel--item']}>
            {child}
          </Carousel.Item>
        );
      })}
    </Carousel>
  ) : (
    <div
      className={classNames(
        styles.content,
        { [styles.content__grid]: isRow },
        { [styles[`content__grid--${numberChildren}-item`]]: isRow },
        {
          [styles[`content__grid--align-${alignContent}`]]:
            alignContent && isRow,
        },
        { [styles[`content__grid--content-separators`]]: contentSeparators },
        className,
      )}
      style={{
        paddingBottom: extraPaddingBottom ?? 0,
      }}
    >
      {children}
    </div>
  );
};
export default ContentGrid;
