import React from 'react';
import styles from './dialog.module.scss';
import type { DialogProperties } from './dialog.types';

const Dialog = ({
  children,
  isOpen = false,
  className = '',
}: DialogProperties) => {
  if (!isOpen) return null;

  return (
    <div role="dialog" className={`${styles.dialog} ${className}`}>
      {children}
    </div>
  );
};

export default Dialog;
