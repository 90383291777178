export enum CLICK_LOCATION {
  HOME_TOP_CAROUSEL = 'home hero carousel',
  PERSONALISED_SECTION = 'tailored for you personalised section',
  POPULAR_RETAILERS = 'home footer carousel',
  HOME_FAVS = 'home favourites section',
  HOME_LAST_VISITED = 'home last visited section',
  COLLECT_ONLINE = 'collect online partners list',
  COLLECT_INSTORE = 'collect in-store partners list',
  SEARCH_RESULTS = 'search results partners list',
  PDP_RELATED_PARTNERS = 'partner display page related partners',
  TOP_PICKS = 'top picks for you',
  GPT_FILTERED_PARTNERS = 'GPT filtered partners',
}
