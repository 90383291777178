import {
  CARD_VARIANT,
  CardProperties,
} from '@/components/integrated/card/card.types';
import { TextBlock } from '@/components/integrated/card/text-block';
import { WithLink } from '@/components/integrated/card/with-link';
import classNames from 'classnames';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import React from 'react';
import Icon from '@/components/data-display/icon';
import styles from '../card.module.scss';

const IconCard = (properties: CardProperties) => {
  const { icon, textBlock, textColour, backgroundColour, cardLink } =
    properties;
  return (
    <WithLink
      href={cardLink ?? undefined}
      className={classNames(
        styles.card,
        styles[`card__${CARD_VARIANT.ICON_CARD}`],
      )}
      backgroundColour={backgroundColour}
    >
      <Icon
        variant={ICON_VARIANT[icon]}
        withBackground
        className={styles.card__icon}
      />
      {textBlock && (
        <TextBlock
          className={styles['card__text-block']}
          textBlock={{
            ...textBlock,
            pillLabel: undefined,
            buttonsCollection: [],
          }}
          textColour={textColour}
        />
      )}
    </WithLink>
  );
};

export default IconCard;
