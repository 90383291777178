import { rest } from 'msw';
import { AVIOS_PREFIX } from '@/constants';
import getFavouritesResultsResponse from './__fixtures__/get-favourites-results.json';

const getFavouritesResults = rest.get(
  `${AVIOS_PREFIX}/api/favourites/`,
  (_, response, context) =>
    response(context.json(getFavouritesResultsResponse)),
);

export default [getFavouritesResults];
