import { CustomLink } from '@/components/custom-link';

export const WithLink = ({
  href,
  className,
  children,
  backgroundColour,
}: {
  href?: string;
  className: string;
  children: React.ReactNode;
  backgroundColour?: string;
}) =>
  href ? (
    <CustomLink
      href={href}
      className={className}
      style={{ background: backgroundColour ?? 'inherit' }}
      data-testid="card-id"
    >
      {children}
    </CustomLink>
  ) : (
    <div
      className={className}
      style={{ background: backgroundColour ?? 'inherit' }}
      data-testid="card-id"
    >
      {children}
    </div>
  );
