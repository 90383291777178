import classNames from 'classnames';
import {
  CONTAINER_VARIANT,
  ContainerProperties,
} from '@/components/integrated/container/container.types';
import styles from './container.module.scss';

const Container = ({
  variant = CONTAINER_VARIANT.COLUMN,
  layout,
  alignContent,
  children,
}: ContainerProperties) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.container,
        styles[`container-${alignContent}`],
      )}
    >
      <div
        className={classNames(
          styles[`container-${variant}`],
          styles[`container--${layout}`],
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
