import React, { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Button from '@/components/inputs/button';
import {
  SIZE as BUTTON_SIZE,
  SIZE,
  VARIANT as BUTTON_VARIANT,
} from '@/components/inputs/button/button.types';
import NextImage from '@/components/data-display/image';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import Dialog from '@/components/feedback/dialog';
import trackEvent from '@/utils/track-event';
import { EVENT_NAME, USER_ACTION } from '@/utils/track-event/track-event.types';
import type {
  MemberGetMemberProperties,
  MemberGetMemberGAEvent,
} from './member-get-member.types';
import styles from './member-get-member.module.scss';

const MemberGetMember = ({
  productTier,
  title,
  toggleText,
  closeLabel,
  offers = [],
  share,
  terms,
  card,
  hasError,
}: MemberGetMemberProperties) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { query } = useRouter();

  const noOffers = offers.length === 0;

  if (hasError || noOffers) {
    return null;
  }

  const [{ value: user_earnings }, { value: friend_earnings }] = offers;

  const trackEventHandler = (user_action: string, reference?: string) => {
    const trackEventObject: MemberGetMemberGAEvent = {
      event: EVENT_NAME.MGM_WIDGET,
      product_tier: productTier,
      user_action,
      user_earnings,
      friend_earnings,
    };
    if (reference) trackEventObject.reference = reference;
    trackEvent(trackEventObject);
  };

  // xl=cpbama RewardsApp
  // xl=cpbawp eStore
  const referenceQuery =
    query?.xl === 'cpbama' ? `xl=${query?.xl}` : 'xl=cpbawp';
  const referenceURL = `${share.referralLink}?${referenceQuery}`;

  const onCopyClick = async () => {
    trackEventHandler(USER_ACTION.MGM_LINK_COPY);
    await navigator.clipboard.writeText(referenceURL);
    setIsDialogOpen(!isDialogOpen);
    setIsCopied(true);
    setTimeout(() => {
      setIsDialogOpen(false);
      setIsCopied(false);
    }, 5000);
  };

  const handleToggleWidget = (reference: string) => {
    const widget_toggle_action = isOpen
      ? USER_ACTION.MGM_WIDGET_CLOSE
      : USER_ACTION.MGM_WIDGET_OPEN;
    trackEventHandler(widget_toggle_action, reference);
    setIsOpen(!isOpen);
  };

  const accordionClasses = classNames(styles.mgm__accordion, {
    [styles['mgm__accordion--open']]: isOpen,
  });

  const shareLabel = isCopied ? share.copiedText : share.copyText;
  const whatsappLabel = 'WhatsApp';

  return (
    <div id="mgm" className={styles.mgm}>
      <div
        className={classNames(styles.mgm__banner, {
          [styles['mgm__banner--open']]: isOpen,
        })}
      >
        <NextImage
          src={card.cardImage}
          alt="Amex card image"
          fill
          className={styles['mgm__banner-image']}
        />
        <div>
          <h3 className={styles['mgm__banner-title']}>{title}</h3>
          <Button
            className={styles['mgm__banner-cta']}
            variant={BUTTON_VARIANT.Contained}
            onClick={() => handleToggleWidget(USER_ACTION.MGM_TOGGLE_BUTTON)}
            trailingIcon={
              isOpen ? ICON_VARIANT.ChevronUp : ICON_VARIANT.ChevronDown
            }
            title={toggleText}
          >
            {toggleText.toUpperCase()}
          </Button>
        </div>
        {isOpen && (
          <Button
            className={styles['mgm__close-btn']}
            onClick={() => handleToggleWidget(USER_ACTION.MGM_X_BUTTON)}
            icon={ICON_VARIANT.X}
            size={BUTTON_SIZE.Large}
            variant={BUTTON_VARIANT.Text}
            aria-label={closeLabel}
            title={closeLabel}
          />
        )}
      </div>
      <div className={accordionClasses}>
        <div className={styles.offers}>
          <div
            className={classNames(styles.offers__wrapper, {
              [styles['offers__wrapper--open']]: isOpen,
            })}
          >
            {offers.map(offer => (
              <div className={styles.offers__card} key={offer.title}>
                <div className={styles.offers__title}>
                  {offer.title.toUpperCase()}
                </div>
                <div className={styles.offers__content}>
                  <div className={styles.offers__amount}>
                    <span>{offer.value}</span>
                  </div>
                  <div className={styles.offers__description}>
                    {offer.extraDescription} {offer.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.share}>
          <h3>{share.title}</h3>
          <p>{terms.termsContent}</p>
          <div className={styles['share__copy-wrapper']}>
            <input type="text" disabled value={referenceURL} />
            <div className={styles['share__dialog-wrapper']}>
              <div className={styles['share__copy-btn-wrapper']}>
                <Dialog className={styles.share__dialog} isOpen={isDialogOpen}>
                  {share.copiedDialogText}
                </Dialog>
                <Button
                  variant={BUTTON_VARIANT.Contained}
                  className={styles.share__copy}
                  onClick={onCopyClick}
                  trailingIcon={
                    isCopied ? ICON_VARIANT.Check : ICON_VARIANT.Link
                  }
                  disabled={isCopied}
                  title={shareLabel}
                >
                  {shareLabel}
                </Button>
              </div>

              {referenceURL && (
                <Button
                  as="link"
                  href={`https://wa.me/?text=${referenceURL}`}
                  target="_blank"
                  variant={BUTTON_VARIANT.Contained}
                  className={styles.share__whatsapp}
                  trailingIcon={ICON_VARIANT.WhatsApp}
                  title={whatsappLabel}
                  onClick={() =>
                    trackEventHandler(USER_ACTION.MGM_WHATSAPP_CLICK)
                  }
                >
                  {whatsappLabel}
                </Button>
              )}
            </div>
          </div>
          <Button
            as="link"
            className={styles.mgm__terms}
            variant={BUTTON_VARIANT.Text}
            href={terms.termsCta}
            target="_blank"
            size={SIZE.Small}
            title={terms.termsLabel}
            onClick={() => trackEventHandler(USER_ACTION.MGM_TERMS_CLICK)}
          >
            {terms.termsLabel}
          </Button>
          {terms.termsExtraContent && (
            <p className={styles['mgm__terms-extra-content']}>
              {terms.termsExtraContent}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default MemberGetMember;
