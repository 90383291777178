import classNames from 'classnames';
import NextImage from '@/components/data-display/image';
import YoutubeEmbed from '@/components/social-media/youtube-embed';
import TitleCopyCtaBlock from '@/components/integrated/title-copy-cta-block';
import { SuperHeroType } from './superhero.types';
import styles from './superhero.module.scss';

const SuperHero = ({
  image,
  youtube,
  titleCopyCtaBlock,
  titleCopyCtaBlockJustifyContent,
  titleCopyCtaBlockAlignItems,
  titleCopyCtaBlockMaxWidth,
  titleCopyCtaBlockPaddingTop,
  titleCopyCtaBlockPaddingBottom,
  titleCopyCtaBlockPaddingLeft,
  titleCopyCtaBlockPaddingRight,
  opacityRgba,
  className,
}: SuperHeroType) => {
  if (!image && !youtube) return null;
  return (
    <div
      className={classNames(styles.superhero, className)}
      style={{
        height: image?.height,
      }}
    >
      <div className={classNames(styles.superhero__image)}>
        {image && !youtube && (
          <>
            <NextImage
              src={image.mobileUrl}
              alt={image?.description ?? ''}
              fill
              overideHeight={image?.height}
              className={classNames(styles['superhero__image--mobile'], {
                [styles[
                  `superhero__image--object-position-${image.imageObjectPosition}`
                ]]: image.imageObjectPosition,
              })}
            />
            <NextImage
              src={image.desktopUrl}
              alt={image?.description ?? ''}
              fill
              overideHeight={image?.height}
              className={classNames(styles['superhero__image--desktop'], {
                [styles[
                  `superhero__image--object-position-${image.imageObjectPosition}`
                ]]: image.imageObjectPosition,
              })}
            />
          </>
        )}
        {youtube && !image && <YoutubeEmbed {...youtube} />}
      </div>
      {image && !youtube && (
        <div
          className={styles['superhero__background-opacity']}
          style={{
            background: opacityRgba ?? 'initial',
          }}
        />
      )}

      {titleCopyCtaBlock && (
        <div
          className={classNames(styles['superhero__title-copy-cta-block'])}
          style={{
            justifyContent: titleCopyCtaBlockJustifyContent ?? 'start',
            alignItems: titleCopyCtaBlockAlignItems ?? 'start',
          }}
        >
          <TitleCopyCtaBlock
            className={classNames(
              styles['superhero__title-copy-cta-block-padding'],
              {
                [styles[
                  `superhero__title-copy-cta-block--max-width-${titleCopyCtaBlockMaxWidth}`
                ]]: titleCopyCtaBlockMaxWidth,
                [styles[
                  `superhero__title-copy-cta-block--padding-top-${titleCopyCtaBlockPaddingTop}`
                ]]: titleCopyCtaBlockPaddingTop,
                [styles[
                  `superhero__title-copy-cta-block--padding-bottom-${titleCopyCtaBlockPaddingBottom}`
                ]]: titleCopyCtaBlockPaddingBottom,
                [styles[
                  `superhero__title-copy-cta-block--padding-left-${titleCopyCtaBlockPaddingLeft}`
                ]]: titleCopyCtaBlockPaddingLeft,
                [styles[
                  `superhero__title-copy-cta-block--padding-right-${titleCopyCtaBlockPaddingLeft}`
                ]]: titleCopyCtaBlockPaddingRight,
              },
            )}
            {...titleCopyCtaBlock}
          />
        </div>
      )}
    </div>
  );
};

export default SuperHero;
