import React from 'react';

import PartnerInlineCard from '@/components/integrated/card/variants/partner-inline-card';
import IconCard from '@/components/integrated/card/variants/icon-card';
import CardCtaImage from '@/components/integrated/card/variants/card-cta-image';
import PromotionalCard from '@/components/integrated/card/variants/promotional-card';
import { CARD_VARIANT, CardProperties } from './card.types';

const Card = (properties: CardProperties) => {
  const { variant } = properties;
  switch (variant) {
    case CARD_VARIANT.PROMOTIONAL_CARD: {
      return <PromotionalCard {...properties} />;
    }
    case CARD_VARIANT.CARD_CTA_IMAGE_LEFT:
    case CARD_VARIANT.CARD_CTA_IMAGE_RIGHT: {
      return <CardCtaImage {...properties} />;
    }
    case CARD_VARIANT.ICON_CARD: {
      return <IconCard {...properties} />;
    }
    case CARD_VARIANT.PARTNER_INLINE_CARD: {
      return <PartnerInlineCard {...properties} />;
    }
    default: {
      return null;
    }
  }
};

export default Card;
