import { CardProperties } from '@/components/integrated/card/card.types';
import classNames from 'classnames';
import React from 'react';
import { TextBlock } from '@/components/integrated/card/text-block';
import styles from '../card.module.scss';
import NextImage from '../../../data-display/image/image';

const CardCtaImage = (properties: CardProperties) => {
  const {
    image,
    textBlock,
    textColour,
    variant,
    backgroundColour,
    isStorybook = false,
  } = properties;
  return (
    <div
      className={classNames(styles.card, styles[`card__${variant}`])}
      data-testid="card-id"
      style={{ background: backgroundColour ?? 'inherit' }}
    >
      {textBlock && (
        <TextBlock
          textBlock={{
            ...textBlock,
            headingTextAlign: 'left',
            bodyTextAlign: 'left',
          }}
          textColour={textColour}
          className={styles[`card__${variant}__text-wrapper`]}
        />
      )}
      {image?.url && (
        <NextImage
          src={image.url}
          alt={image.description ?? ''}
          fill
          className={styles[`card__${variant}__image-wrapper`]}
          isStorybook={isStorybook}
        />
      )}
    </div>
  );
};

export default CardCtaImage;
