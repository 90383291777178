import { Eyebrow, Heading } from '@alto-avios/alto-ui';
import { markdownToHTML } from '@/utils/markdown-to-html';
import classNames from 'classnames';
import { TextBlockProperties } from './text-block.types';
import styles from './text-block.module.scss';

const TextBlock = ({
  htmlId,
  eyebrowLabel = null,
  headingTag = 'h1',
  headingSize = 'xl',
  heading = null,
  body = null,
  isFullBleed,
}: TextBlockProperties) => {
  if (!eyebrowLabel && !headingTag && !headingSize && !heading && !body) {
    return null;
  }

  return (
    <div
      className={classNames(styles['text-block'], {
        [styles['text-block--full-bleed']]: isFullBleed,
      })}
      id={htmlId}
    >
      <div className={styles['text-block__container']}>
        {eyebrowLabel && (
          <Eyebrow as="span" styleVariant="collect">
            {eyebrowLabel}
          </Eyebrow>
        )}

        {heading && headingTag && headingSize && (
          <Heading as={headingTag} size={headingSize} fgColor="accentSecondary">
            {heading}
          </Heading>
        )}

        {body && (
          <div
            dangerouslySetInnerHTML={{
              __html: markdownToHTML(body),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TextBlock;
