import classNames from 'classnames';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import { markdownToHTML } from '@/utils/markdown-to-html';
import type {
  TitleCopyCtaBlockProperties,
  TextAlignValue,
} from './title-copy-cta-block.types';
import { TEXT_ALIGN } from './title-copy-cta-block.types';
import styles from './title-copy-cta-block.module.scss';

const TitleCopyCtaBlock = ({
  id,
  headingOne,
  headingOneColor,
  headingOneTextAlign = TEXT_ALIGN.Left,
  headingTwo,
  headingTwoColor,
  headingTwoTextAlign = TEXT_ALIGN.Left,
  headingThree,
  headingThreeColor,
  headingThreeTextAlign = TEXT_ALIGN.Left,
  headingFour,
  headingFourColor,
  headingFourTextAlign = TEXT_ALIGN.Left,
  description,
  descriptionColor,
  descriptionTextAlign = TEXT_ALIGN.Left,
  overrideDescriptionFontSize,
  linkPrimary,
  linkSecondary,
  openLinksInNewTab,
  primaryCta,
  secondaryCta,
  openCtasInNewTab,
  className = '',
  style = {},
  inlineButtons = false,
  isFullBleed,
}: TitleCopyCtaBlockProperties) => {
  if (
    !headingOne &&
    !headingTwo &&
    !headingThree &&
    !headingFour &&
    !description
  ) {
    return null;
  }
  const renderLinkCtaBlock =
    linkPrimary?.url ||
    linkSecondary?.url ||
    primaryCta?.url ||
    secondaryCta?.url;

  return (
    <div
      className={classNames(
        styles['title-copy-cta-block-container'],
        { [styles['title-copy-cta-block-full-bleed']]: isFullBleed },
        className,
      )}
    >
      <div
        className={classNames(styles['title-copy-cta-block'], {
          [styles['title-copy-cta-block--inline']]: inlineButtons,
        })}
        style={style}
        id={id}
      >
        <TitleCopyCtaBlockHeadings
          headingOne={headingOne}
          headingOneColor={headingOneColor}
          headingOneTextAlign={headingOneTextAlign}
          headingTwo={headingTwo}
          headingTwoColor={headingTwoColor}
          headingTwoTextAlign={headingTwoTextAlign}
          headingThree={headingThree}
          headingThreeColor={headingThreeColor}
          headingThreeTextAlign={headingThreeTextAlign}
          headingFour={headingFour}
          headingFourColor={headingFourColor}
          headingFourTextAlign={headingFourTextAlign}
          description={description}
          descriptionColor={descriptionColor}
          descriptionTextAlign={descriptionTextAlign}
          overrideDescriptionFontSize={overrideDescriptionFontSize}
        />
        {renderLinkCtaBlock && (
          <div>
            <TitleCopyCtaBlockLinks
              linkPrimary={linkPrimary}
              linkSecondary={linkSecondary}
              openLinksInNewTab={openLinksInNewTab}
            />
            <TitleCopyCtaBlockCTAS
              primaryCta={primaryCta}
              secondaryCta={secondaryCta}
              openCtasInNewTab={openCtasInNewTab}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const TitleCopyCtaBlockHeadings = ({
  headingOne,
  headingOneColor,
  headingOneTextAlign,
  headingTwo,
  headingTwoColor,
  headingTwoTextAlign,
  headingThree,
  headingThreeColor,
  headingThreeTextAlign,
  headingFour,
  headingFourColor,
  headingFourTextAlign,
  description,
  descriptionColor,
  descriptionTextAlign,
  overrideDescriptionFontSize,
}: TitleCopyCtaBlockProperties) => {
  const MAX_DESCRIPTION_WORDS = 2000;
  const descriptionWords = description?.split(' ').length ?? 0;
  const REVERT_LAYER_CSS = 'revert-layer';

  return (
    <div className={styles['title-copy-cta-block__headings-container']}>
      <div className={styles['title-copy-cta-block__headings']}>
        {headingOne && (
          <h1
            style={{
              color: headingOneColor ?? REVERT_LAYER_CSS,
              textAlign: headingOneTextAlign as TextAlignValue,
            }}
          >
            {headingOne}
          </h1>
        )}

        {headingTwo && (
          <h2
            style={{
              color: headingTwoColor ?? REVERT_LAYER_CSS,
              textAlign: headingTwoTextAlign as TextAlignValue,
            }}
          >
            {headingTwo}
          </h2>
        )}

        {headingThree && (
          <h3
            style={{
              color: headingThreeColor ?? REVERT_LAYER_CSS,
              textAlign: headingThreeTextAlign as TextAlignValue,
            }}
          >
            {headingThree}
          </h3>
        )}

        {headingFour && (
          <h4
            style={{
              color: headingFourColor ?? REVERT_LAYER_CSS,
              textAlign: headingFourTextAlign as TextAlignValue,
            }}
          >
            {headingFour}
          </h4>
        )}
      </div>
      {description && (
        <div
          style={{
            color: descriptionColor ?? REVERT_LAYER_CSS,
            textAlign: descriptionTextAlign as TextAlignValue,
            fontSize: overrideDescriptionFontSize
              ? `var(${overrideDescriptionFontSize})`
              : 'initial',
          }}
          className={classNames(styles['title-copy-cta-block__description'])}
          dangerouslySetInnerHTML={{
            __html: markdownToHTML(
              descriptionWords < MAX_DESCRIPTION_WORDS
                ? description
                : `${description
                    .split(' ')
                    .splice(0, MAX_DESCRIPTION_WORDS)
                    .join(' ')}...`,
            ),
          }}
        />
      )}
    </div>
  );
};

export const TitleCopyCtaBlockCTAS = ({
  primaryCta,
  secondaryCta,
  openCtasInNewTab,
}: Pick<
  TitleCopyCtaBlockProperties,
  'primaryCta' | 'secondaryCta' | 'openCtasInNewTab'
>) => {
  if (!primaryCta?.label && !secondaryCta?.label) {
    return null;
  }

  return (
    <div className={styles['title-copy-cta-block__ctas']}>
      {primaryCta?.label && (
        <Button
          as="link"
          variant={primaryCta.variant ?? BUTTON_VARIANT.Contained}
          href={primaryCta.url}
          className={styles['title-copy-cta-block__primary-cta']}
          title={primaryCta.label ?? ''}
          trailingIcon={primaryCta.trailingIcon}
          target={openCtasInNewTab ? '_blank' : '_self'}
        >
          {primaryCta.label}
        </Button>
      )}
      {secondaryCta?.label && (
        <Button
          as="link"
          variant={secondaryCta.variant ?? BUTTON_VARIANT.Outlined}
          href={secondaryCta.url}
          className={styles['title-copy-cta-block__secondary-cta']}
          title={secondaryCta.label ?? ''}
          trailingIcon={secondaryCta.trailingIcon}
          target={openCtasInNewTab ? '_blank' : '_self'}
        >
          {secondaryCta.label}
        </Button>
      )}
    </div>
  );
};

export const TitleCopyCtaBlockLinks = ({
  linkPrimary,
  linkSecondary,
  openLinksInNewTab,
}: Pick<
  TitleCopyCtaBlockProperties,
  'linkPrimary' | 'linkSecondary' | 'openLinksInNewTab'
>) => {
  if (
    !linkPrimary?.label &&
    !linkPrimary?.url &&
    !linkSecondary?.label &&
    !linkSecondary?.url
  ) {
    return null;
  }
  return (
    <div className={styles['title-copy-cta-block__links']}>
      {linkPrimary?.label && (
        <Button
          as="link"
          variant={BUTTON_VARIANT.Text}
          href={linkPrimary?.url ?? ''}
          className={styles['title-copy-cta-block__primary-link']}
          title={linkPrimary.label}
          target={openLinksInNewTab ? '_blank' : '_self'}
        >
          {linkPrimary.label}
        </Button>
      )}
      {linkSecondary?.label && (
        <Button
          as="link"
          variant={BUTTON_VARIANT.Text}
          href={linkSecondary?.url ?? ''}
          className={styles['title-copy-cta-block__secondary-link']}
          title={linkSecondary.label}
          target={openLinksInNewTab ? '_blank' : '_self'}
        >
          {linkSecondary.label}
        </Button>
      )}
    </div>
  );
};

export default TitleCopyCtaBlock;
