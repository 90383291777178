import NextImage from '@/components/data-display/image';
import YoutubeEmbed from '@/components/social-media/youtube-embed';
import TitleCopyCtaBlock from '@/components/integrated/title-copy-cta-block';
import type { ArticleStoryProperties } from './article-story.types';
import styles from './article-story.module.scss';

const ArticleStory = ({
  id,
  className = '',
  imageSrc,
  imageAlt,
  youtubeID,
  youtubeTitle,
  youtubeAutoPlay,
  content,
}: ArticleStoryProperties) => {
  return content ? (
    <div className={`${styles['article-story']} ${className}`} id={id}>
      {youtubeID && !imageSrc && (
        <div className={styles['article-story__youtube']}>
          <YoutubeEmbed
            youtubeID={youtubeID}
            autoPlay={youtubeAutoPlay}
            title={youtubeTitle}
          />
        </div>
      )}
      {imageSrc && !youtubeID && (
        <NextImage
          className={styles['article-story__image']}
          src={imageSrc}
          alt={imageAlt ?? imageSrc}
          fill
        />
      )}
      <div className={styles['article-story__content']}>
        <TitleCopyCtaBlock {...content} />
      </div>
    </div>
  ) : null;
};

export default ArticleStory;
