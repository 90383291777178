import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';

export enum TEXT_ALIGN {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type TextAlignValue =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';

export type CTAProperties = {
  label?: string | null;
  url?: string;
  variant?: BUTTON_VARIANT;
  trailingIcon?: ICON_VARIANT;
};

export type TitleCopyCtaBlockProperties = {
  id?: string;
  headingOne?: string | null;
  headingOneColor?: string | null;
  headingOneTextAlign?: string | null;
  headingTwo?: string | null;
  headingTwoColor?: string | null;
  headingTwoTextAlign?: string | null;
  headingThree?: string | null;
  headingThreeColor?: string | null;
  headingThreeTextAlign?: string | null;
  headingFour?: string | null;
  headingFourColor?: string | null;
  headingFourTextAlign?: string | null;
  description?: string | null;
  descriptionColor?: string | null;
  descriptionTextAlign?: string;
  overrideDescriptionFontSize?: string;
  linkPrimary?: CTAProperties;
  linkSecondary?: CTAProperties;
  openLinksInNewTab?: boolean | null;
  primaryCta?: CTAProperties;
  secondaryCta?: CTAProperties;
  openCtasInNewTab?: boolean | null;
  className?: string;
  style?: object;
  inlineButtons?: boolean | null;
  isFullBleed?: boolean | null;
};
